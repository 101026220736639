@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Gamer;
  src: url(./assets/fonts/Gamer.ttf);
}

:root {
  --default-padding: 10px;
  --default-rounded-border: 100px;
  --default-dogo-font-family: Gamer;
  --default-text-font-family: "Poppins", sans-serif;
  --default-blue: #012b54;
  --default-font-size: 18px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


