.values {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-image:  url(../../assets/images/valuesBg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.card {
    display: flex;
    margin-top: -10vh;
}

@media screen and (max-width: 1600px) {

    .values {
        height: 110vh;
        background-size: 100vw 110vh;
    }

}

@media screen and (max-width: 600px) {

    .values {
        background-image: linear-gradient(to bottom, rgba(223, 10, 10, 0.397), rgb(223, 10, 10, 0.397)), url(../../assets/images/mobile/valuesBg.png);
        height: 80vh;
    }

    .card {
        margin-top: 0vh;
    }


}


@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}




.bottomtopup {
    animation: bottomtopup 4s;
}