.onu {
    background-color: #ffd200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    background-image: url(../../assets/images/onuBg.png);
    background-size: 100vw 100%;
}

.h1 {
    font-family: var(--default-dogo-font-family);
    color: var(--default-blue);
    font-size: 150px;
    text-align: center;
    width: 80vw;

}

.subtitle {
    font-family: var(--default-text-font-family);
    color: var(--default-blue);
    font-size: 46px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 50px;
    width: 80vw;

}

.values {
    margin-bottom: 8vh;

}

@media screen and (max-width: 1600px) {

    .h1 {
        font-size: 120px;
    }

    .onu {
        padding-top: 20vh;
        padding-bottom: 7vh;
    }

    .subtitle {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .values {
        transform: scale(0.8);
    }


}


@media screen and (max-width: 600px) {
    .onu {
        background-image: none;
        padding-top: 5vh;
    }

    .h1 {
        font-size: 35px;
    }

    .subtitle {
        font-size: 12px;
    }

    .gridContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 10px;
    }

    .gridItem {
        flex: 0 1 calc(50% - 10px);
        /* Considera a gap de 10px */
        margin-bottom: 10px;
    }

    .gridItemImage {
        width: 100%;
        height: auto;
    }

    .values {
        margin-bottom: -7vh;
    }
}



/**/
@keyframes intro_transition {
    from {
        transform: translateX(200%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes intro_transition2 {
    from {
        transform: translateX(-200%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes spring {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(0.9);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}

.none {
    display: none;
}

.start {
    animation: intro_transition 2s;
}

.end {
    animation: intro_transition2 2s;
}

.fade {
    animation: fade 4s;
}


.uptodown {
    animation: uptodown 4s;
}