.earth {
    background-color: rgb(5, 120, 228);

    background-image: linear-gradient(to bottom, #0387fadc, #005196), url(../../assets/images/earthBg.png);

    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 8vh;
}

.title {
    margin-top: 10vh;
    margin-bottom: 5vh;
    font-family: var(--default-dogo-font-family);
    font-size: 100px;
    font-weight: 800;
    color: yellow;
}

.justify {
    text-align: center;
    background-color: red;
}

.text {
    display: flex;
    justify-content: center;
    color: white;
    font-family: var(--default-text-font-family);
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 5vh;
}

.customSection {
    display: flex;
}

.secondColumn {
    margin-left: 4vw;
}

.earth_image {}


@media screen and (max-width: 1600px) {


    .text {
        font-size: 16px;
    }

    .customSection img {
        width: 35vw;
    }

    .title img {
        transform: scale(0.8);
    }

    .bgDiv {
        width: 100vw;
        height: 5vh;
        background-color: red;
    }

}


@media screen and (max-width: 600px) {

    .earth {
        background-image: linear-gradient(to bottom, #0387fadc, #005196);
        padding-bottom: 4vh;
    }

    .title {
        margin-top: 6vh;
    }

    .title img {
        width: 80vw;
    }

    .text {
        font-size: 12px;
    }

    .customSection {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .firstColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .firstColumnImage img {
        width: 90vw;
    }



    .imageRow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }

    .imageRow :first-child {
        margin-right: 8px;
    }


    .imageWrapper {
        /* Se você quiser que as imagens tenham um tamanho fixo ou responsivo, você pode ajustar aqui. */
    }

    .responsiveImage {}

    @keyframes bottomtopup {
        0% {
            position: relative;
            bottom: -12vh;
            opacity: 0;
            transform: scale(0.7);
        }

        100% {
            position: relative;
            bottom: 0vh;
            opacity: 1;
            transform: scale(1);
        }
    }


    @keyframes uptodown {
        0% {
            position: relative;
            bottom: 12vh;
            opacity: 0;
            transform: scale(0.7);
        }

        100% {
            position: relative;
            bottom: 0vh;
            opacity: 1;
            transform: scale(1);
        }
    }


    @keyframes intro_transition {
        from {
            transform: translateX(-200%);
        }

        to {
            transform: translateX(0%);
        }
    }



    .bottomtopup {
        animation: bottomtopup 4s;
    }

    .uptodown {
        animation: uptodown 4s;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .none {
        display: none;
    }
}

@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes intro_transition {
    from {
        transform: translateX(-200%);
    }

    to {
        transform: translateX(0%);
    }
}



.bottomtopup {
    animation: bottomtopup 4s;
}

.uptodown {
    animation: uptodown 4s;
    display: flex;
    align-items: center;
    justify-content: center;
}


.none {
    display: none;
}




@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes intro_transition {
    from {
        transform: translateX(-200%);
    }

    to {
        transform: translateX(0%);
        transform: scale(0.8);
    }
}

@keyframes intro_transition2 {
    from {
        transform: translateX(200%);
    }

    to {
        transform: translateX(0%);
    }
}




.bottomtopup {
    animation: bottomtopup 4s;
}

.uptodown {
    animation: uptodown 4s;
}

.start {
    animation: intro_transition 4s;
}

.end {
    animation: intro_transition2 5s;
}

.none {
    display: none;
}