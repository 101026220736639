.cases {
    padding-bottom: 8vh;
    background-color: #005fae;
    display: flex;
    flex-direction: column;
}



.title {
    font-family: var(--default-dogo-font-family);
    font-size: 160px;
    font-weight: 800;
    color: yellow;
    padding-top: 5vh;
    padding-bottom: 8vh;
    display: flex;
    justify-content: center;
}

.roundedExpandedCard {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
}

.videos {
    display: flex;
    justify-content: space-around;
}


.video iframe {
    border-radius: 25px;
}


@media screen and (max-width: 1600px) {
    .videos iframe {
        width: 350px;
        height: 260px;
    }

    .videos :nth-child(2) iframe {
        width: 500px;
        height: 300px;
        margin-top: -20px;
    }

    .title {
        font-size: 100px;
    }

    .roundedExpandedCard img {
        width: 50vw;
    }
}

@media screen and (max-width: 600px) {

    .cases {
        padding-bottom: 5vh;
    }

    .title {
        font-size: 40px;
    }

    .video iframe {
        width: 220px !important;
        height: 120px !important;
        border-radius: 5px;
    }

    .roundedExpandedCard img {
        width: 80vw;
    }
}


@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes intro_transition {
    from {
        transform: translateX(-200%);
    } to {
        transform: translateX(0%);
    }
}




.bottomtopup {
    animation: bottomtopup 4s;
}

.uptodown {
    animation: uptodown 4s;
}

.start {
    animation: intro_transition 4s;
}

.none {
    display: none;
}