.materials {
    background-color: rgb(11, 11, 66);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(11, 11, 66)), url(../../assets/images/materialsBg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    padding-bottom: 8vh;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title img {
    margin-top: 50px;
    width: 60vw;
}

.customSection {
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    padding-top: 15vh;
    flex-direction: row;
}


.banner {
    flex: 5;
    display: flex;
    justify-content: center;

}

.banner img {
    width: 80%;
    border-radius: 25px;
    position: relative;


}

.list {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list div div {
    margin-bottom: 4vh;
}

.footer {
    display: flex;
    justify-content: center;
    margin-top: 2vh;


}

.imgBannerLivro{
    width: 100%;
    cursor: pointer;
}

.booksList{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin-top: 4rem;
}

.bookList_title{
    font-size: 2.2rem;
    color: white;
}

.footer img {
    width: 62vw;
}


.footer :first-child {
    /* margin-right: 20px; */
}

.el_list img {
    width: 250px;
}

.icon {
    display: flex;
    flex-direction: row;
    color: white;
    text-align:center;
    justify-content: center;
    align-items: center;
  
}

.icon :first-child {
    margin-right: 20px;
}

.textIcon {
    width: 30vw;
}

.titleBookDescription {
    font-size: 25px;
    font-weight: 500;
    font-family: Gamer;
    text-wrap: wrap;


}

.bookDescription {
    font-family: Poppins;
    text-align: justify;
}

.icon img {
    width: 10vw;
}



@media screen and (max-width: 1600px) {

    .materials {

        background-size: 100vw 110vh;
    }


}

.bookWindowNone {
    display: none;
}

.bookWindow {
    top: 10vh;
    left: 10vw;
    z-index: 99;
    width: 80vw;
    height: 80vh;
    background-color: white;
    position: fixed;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
}

.close {
    position: relative;
    left: 2vw;
    top: 1vh;
}

/* EducationalBanner.module.css */
.bannerContainer {
    font-family: 'Arial', sans-serif;
    max-width: 900px;
    /* ou a largura que preferir */
    margin: auto;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    
}

.header {
    background-color: #204080;
    color: white;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    border-radius: 20px;
}

.body {
    display: flex;
}

.leftSection,
.rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.box {
    margin: 10px;
    background-color: #c70f0f;
    padding: 15px;
    border: 0px solid #000000;
    border-radius: 20px;
}


.title1 {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
}

.list1 {
    list-style: none;
    padding: 0;
}

.list1 li,p {
    margin-bottom: 5px;
    color: white;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
}
@media screen and (max-width: 600px) {

    @keyframes intro_transition {
        from {
            transform: translateX(-200%);
        } to {
            transform: translateX(0%);
        }
    }
    
    .start1 {
        animation: intro_transition 4s;
    }
    

    .bannerContainer {
        padding-left: 3vw;
        padding-right: 3vw;
        overflow: scroll;
    }


    .body {
        display: flex;
        flex-direction: column;
        
    }


    .materials {

        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(11, 11, 66)), url(../../assets/images/mobile/materialsBg.png);


    }


    .banner {
        margin-bottom: 3.5vh;
    }

    .banner img {
        width: 85vw;
    }

    .customSection {
        flex-direction: column;
        padding-left: 15%;
        padding-top: 4vh;
    }

    .el_list img {
        width: 150px;
        margin-right: 2vw;
    }

    .list {
        flex-direction: row;
    }

    .titleBookDescription {
        font-size: 10px;
        font-weight: 500;
        font-family: Gamer;


    }

    .bookDescription {
        font-size: 7px;
    }

    .icon {
        flex-direction: column;
    }

    .icon :first-child {
        margin-right: 0px;
    }


    .footer {
        margin-top: 4vh;
    }

    .booksList{
        width: 100%;
        padding: 1rem;
        text-align: center;
        margin-top: 0;
    }

    .booksList img{
        width: 100%;
    }

    .footer :first-child {
        margin-right: 0px;
    }

    .textIcon {
        width: 55vw;
        display: flex;
        flex-direction: column;
        padding-left: 1vw;
        padding-right: 1vw;

    }

    .textIcon div {
        margin-bottom: 2px;
    }

    .textIcon div :first-child {
        margin-bottom: 5px;
    }

    .image_and_title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .image_and_title :first-child {
        margin-right: 5px;
    }

    .bookWindow {
        top: 5vh;
        left: 2.5vw;
        z-index: 99;
        width: 95vw;
        height: 90vh;
        border-radius: 5px;
        overflow: scroll;
        overflow-x: hidden;
    }
}

@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes intro_transition {
    from {
        transform: translateX(-200%);
    } to {
        transform: translateX(0%);
    }
}

.start {
    animation: intro_transition 4s;
    display: flex;
}

.bottomtopup {
    animation: bottomtopup 4s;
}

.uptodown {
    animation: uptodown 4s;
    display: flex;
    align-items: center;
    justify-content: center;
}


.none {
    display: none;
}