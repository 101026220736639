

.footer {
    height: 20vh;
    background-color: #012b54;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer > * {
    margin-right: 2vw;
}

.footer :last-child {
    margin-right: 0;
}

.logoIcon {
    width: calc(var(--default-navbar-height) * 3);
}

.socialMedia {
    display: flex;
    flex-direction: column;
}

.socialMedia img {
    margin-bottom: 5px;
}

.columnContact {
    display: flex;
    flex-direction: row;
}

.centerElements {
    display: flex;
    color: #ffe026;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.centerElements :first-child {
    margin-right: 5px;
}

@media screen and (max-width: 1600px) {
    .footerLogoIcon img {
        width: 10vw;
    }

    .info img{
        width: 22vw;
    }

    .social img {
        width: 1vw;
    }

    .contact img {
        width: 20vw;
    }
    
}


.info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}



@media screen and (max-width: 600px) {
  
   
    .columnContact {
        flex-direction: column;
        font-size: 10px;
        align-items: start;
    }
    .centerElements {
        font-weight: 400;
    }
    .socialMedia img {
        width: 12px;
    }
    .footerLogoIcon img {
        width: 25vw;
    }
    .row{
        display: flex;
        flex-direction: row;
    }
}
