*{
    margin: 0;
    padding: 0  ;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    gap: 4rem;
    width: 100%;
    padding: 4rem 0;
}

.title {
    font-size: 3rem;
    text-align: center;
    color: rgba(0, 33, 102, 1);
}

.cardList{
    display: flex;
    gap: 4rem;
}

.card{
    background-color: rgba(0, 33, 102, 1);
    color: white;
    max-width: 500px;
    height: 480px;
    border-radius: 1rem;
    border: 1px solid rgba(0, 32, 102, 0.226);
    overflow: hidden;
    padding-bottom: 1rem;
}

.cardImg{
    width: 100%;
    height: 180px;
}
.cardTitle{
    font-size: 1.8rem;
}

.cardTextoDestaqueBlock{
    color: rgba(253, 196, 19, 1);
}
.cardTextoDestaqueCode{
    color: rgba(221, 1, 1, 1);
}

.imgContainerCode{
    width: 100%;
    height: 220px;
    background-position: center right;
    background-size: cover;
}

.imgContainerBlock{
    width: 100%;
    height: 220px;
    background-position: center right;
    background-size: cover;
}

.cardBody{
    padding: 1rem;
    padding-bottom: 2rem;
    height: calc(100% - 240px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    gap: .7rem;
}

.cardBody p{
    max-width: 340px;
    text-align: center;
    margin: 0 auto; 
}

.cardBodyAction{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.btnDownload{
    background-color: rgba(221, 1, 1, 1);
    color: white;
    padding: 1rem;
    border: 0;
    border-radius: 2rem;
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all .4s ease-in-out;
}

.btnDownload:hover{
    transform: translateY(-4px);
}

@media screen and (max-width: 540px) {

.title {
    font-size: 2rem;
}
    .cardList{
        flex-direction: column;
        padding: 1rem;
    }

    .card{
        width: 100%;

    }
}