.button {
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: var(--default-rounded-border);
    border-style: none;
    background-color: #d40e16;
    font-family: var(--default-dogo-font-family);
    font-style: normal;
    font-size: 30px;
    color: #ffe026;
    cursor: pointer;
    font-weight: 600;
}

.button a {
    color: #ffe026;
}



@media screen and (max-width: 600px) {
    .button {
        font-size: 22px;
    }
}