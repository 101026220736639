.testimonials {
    background-color: #fedd27;
    padding-bottom: 15vh;
}

.title {
    display: flex;
    justify-content: center;
    padding-top: 9vh;
}

.section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    padding-left: 15%;
    padding-right: 15%;
    top: 5vh;
}

.section img {
    height: 70vh;
}

@media screen and (max-width: 1600px) {
    
    .title img {
        transform: scale(0.7);
    }
    

    .section img {
        height: 60vh;
    }
    
    .testimonials {
    }
}


@media screen and (max-width: 600px) {

   .testimonials {

    background-color: #d4ba24;
   }

   .title {
    padding-top: 7vh;
   }
    .title img {
        width: 100vw;
        transform: scale(0.9);
    }

    .title {
        margin-bottom: 12vh;
    }

    .card img {
        width: 50vw;
        min-height: 60vw;

    }
}

@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}






.bottomtopup {
    animation: bottomtopup 4s;
}

.uptodown {
    animation: uptodown 4s;
}