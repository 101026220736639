:root {
    --default-intro-background-color: #012b54;
    --iframe-border-radius: 50px;
    --iframe-border-radius-mobile: 10px;
    --default-intro-font-size: 16px;
    --default-intro-font-weight: 500;
}

@keyframes intro_transition {
    from {
        transform: translateX(200%);
    } to {
        transform: translateX(0%);
    }
}

@keyframes intro_transition2 {
    from {
        transform: translateX(-200%);
    } to {
        transform: translateX(0%);
    }
}

@keyframes spring {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(0.9);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

.none {
    display: none;
}

.start{
    animation: intro_transition 2s ;
}

.end {
    animation: intro_transition2 2s;
}

.spring {
    animation: spring 1s;
}




.intro_section {
    background-color: var(--default-intro-background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    
}


.quem_somos {
    margin-top: 10vh;
    margin-bottom: 2vh;
}




.description {

    width: 60%;
    text-align: center;
  text-wrap:balance;
    font-size: var(--default-intro-font-size);
    font-family: var(--default-text-font-family);
    font-weight: var(--default-intro-font-weight);

}

.yellow {
    color: yellow;
}

.iframe {
    margin-top: 8vh;
    border-radius: var(--iframe-border-radius);

}



@media screen and (max-width: 600px) {
    .intro_section {
        padding-bottom: 8vh;
    }

    .quem_somos {
        margin-top: 5vh;
    }

    .quem_somos img {
        width: 50vw;

    }

    .description {
        width: 90%;
        font-size: 12px;
        text-align: justify;
    }

    .iframe {
        margin-top: 4vh;
        width: 90vw;
        height: 25vh;
        border-radius: var(--iframe-border-radius-mobile);
    }

}