:root {
    --default-banner-width: 100vw;
}

.banner {
    background-color: red;
    background-image: url(../../assets/images/newbanner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 90vh;
}

.bannerLayer {
    width: 100%;
    height: 90vh;
}

@media screen and (max-width: 600px) {
    .banner {
        background-image: url(../../assets/banner1_mobile.jpg);
        height: 90vh;
    }

    .bannerLayer {
        width: 100%;
        height: 90vh;
    }
}