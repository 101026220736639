.costumers {

    background-image: url(../../assets/images/costumersBg.png);
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120vh;
   
    /**/
}

.title {
    font-family: var(--default-dogo-font-family);
    font-size: 200px;
    color: #012b54;
    margin-bottom: 3vh;
}

.roundedExpandedCard {
    margin-bottom: 1vh;
}

.cities{
    margin-top: 6vh;
}



@media screen and (max-width: 1600px) {

    .title {
        font-size: 150px;
    }

    .costumers {
        height: 120vh;
        
    }

    .roundedExpandedCard {
        transform: scale(1);
    }

    .costumers {
        padding-left: 15px;
        padding-right: 15px;
    }



}

@media screen and (max-width: 600px){
    
    .costumers {
        height: 50vh;
    }

    .title {
        font-size: 80px;
    }

    .roundedExpandedCard {
        font-size: 1px !important;
    }

   

    .cities img {
        width: 17vw;
    }

    
}

.whiteColor {
    color: white;
}



@keyframes bottomtopup {
    0% {
        position: relative;
        bottom: -12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes intro_transition {
    from {
        transform: translateX(-200%);
    } to {
        transform: translateX(0%);
    }
}



.bottomtopup {
    animation: bottomtopup 4s;
}

.uptodown {
    animation: uptodown 4s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start {
    animation: intro_transition 4s;
}

.none {
    display: none;
}