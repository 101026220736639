.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transform: scale(1.5);
}


.slider img {
    width: 10vw;
    max-height: 9vh;
}

.leftArrow {
    cursor: pointer;
}

.rightArrow {
    cursor: pointer;
}

.leftArrow img {
    width: 3vw;
}

.rightArrow img {
    width: 3vw;
}

.sliderContent {
    display: flex;
}

@media screen and (max-width: 600px) {

    .slider img {
        
    }

    .leftArrow img {
        min-width: 10px;
        max-width: 10px;
        height: 10px;
    }
    
    .rightArrow img {
        min-width: 10px;
        max-width: 10px;
        height: 10px;
    }

}