.card {
    padding: 50px;
    width: 20vw;
    background-color: #012a54d0;
    color: white;
    border-radius: 50px;
    height: 60vh;
    margin-right: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.title {
    font-family: var(--default-dogo-font-family);
    color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150px;
    font-weight: 800;
    flex: 1;
}

.text {
    font-family: var(--default-text-font-family);
    text-align: center;
    border-width: 0px;
    border-top-width: 10px;
    padding-top: 5vh;
    border-style: solid;
    justify-content: start;
    font-size: 23px;
    flex: 2;

}

@media screen and (max-width: 1600px) {


    .title {
        font-size: 100px;
    }

    .text {
        font-size: 16px;
    }

}


@media screen and (max-width: 600px) {

    .card {
        padding: 20px;
        width: 45vw;
        border-radius: 10px;
        height: auto;

        padding-top: 10vh;
        padding-bottom: 10vh;
    }

    .title {
       font-size: 32px;
    }

    .text {
        font-size: 8px;
        border-top-width: 1px;
        padding-top: 1vh;
    }

}

