.press {
    background-color: #012b54;
    padding-bottom: 6vh;
}

.title{
    padding-top: 5%;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
}

.videos {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.video1 {
    margin-right: 1%;
}

.video1 > * {
    border-radius: 25px;
}

.video2 > *{
    border-radius: 25px;
}

.roundedExpandedCard{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 8vh;
}


@media screen and (max-width: 1600px) {

    .title {
        font-size: 150px;
    }


    .title img {
        transform: scale(0.7);
    }

    .costumers {
        height: 120vh;
    }

    .roundedExpandedCard {
        transform: scale(0.8);
    }

    .videos {
        transform: scale(0.9);
    }



}

@media screen and (max-width: 600px) {

   

    .title img {
        width: 100vw;
    }

    .video1{
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .video2{
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .video1 > * {
        border-radius: 5px;
    }

    .video2 > * {
        border-radius: 5px;
    }

    .mobile_videos_slider {
        margin-top: 8vh;
    }

    .roundedExpandedCard img {
        width: 100vw;
    }
}





/**/
@keyframes intro_transition {
    from {
        transform: translateX(200%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes intro_transition2 {
    from {
        transform: translateX(-200%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes spring {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(0.9);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes uptodown {
    0% {
        position: relative;
        bottom: 12vh;
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        position: relative;
        bottom: 0vh;
        opacity: 1;
        transform: scale(1);
    }
}

.none {
    display: none;
}

.start {
    animation: intro_transition 2s;
}

.end {
    animation: intro_transition2 2s;
}

.fade {
    animation: fade 4s;
}


.uptodown {
    animation: uptodown 4s;
}