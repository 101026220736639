.button {
    padding-bottom: 35px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: var(--default-rounded-border);
    border-style: none;
    background-color: #d40e15ce;
    font-family: var(--default-dogo-font-family);
    font-style: normal;
    font-size: 80px;
    color: #ffe026;
    cursor: pointer;
    font-weight: 500;
}