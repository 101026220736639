:root{
    --default-navbar-background-color: #012a54;
    --default-navbar-height: 7vh;
    --default-navbar-height-mobile: 3vh;
    --default-navbar-width: 100vw;
    --default-navbar-padding: 1.5vh;
}

.navbarBlankLayer {
    height: var(--default-navbar-height);
    padding-top: var(--default-navbar-padding);
    padding-bottom: var(--default-navbar-padding);
    background-color: white;
}

.navbar {
    background-color: var(--default-navbar-background-color);
    height: var(--default-navbar-height);
    width: 100vw;
    padding-top: 2.5vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    backface-visibility: hidden;
    top: 0;
    z-index: 1;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.4);

   
}

/*
.navbar > * {
    border-width: 1px;
    border-style: solid;
}

*/
.navbar div {

}

.navbar > * {
    margin-left: 15px;
}

.navbar div {
    font-size: 15px;
}


.logo{
    width: calc(var(--default-navbar-height) * 2);
}


a {
    color: white;
    text-decoration-line: none;
}

.mobileNavbar {
    display: flex;
    justify-content: space-around;  
    align-items: center;
    width: 100%;
    margin-left: 0px;
}

@media screen and (max-width: 600px) {
    .navbar {
        
        height: var(--default-navbar-height-mobile);
    
       
    }

    .navbarBlankLayer {
        height: var(--default-navbar-height-mobile);
        padding-top: var(--default-navbar-padding);
        padding-bottom: var(--default-navbar-padding);
        background-color: white;
    }

    @keyframes anim_ {
        from {
            width: 0vw;
        }
        to{
            width: 70vw;
        }
    }

    .menu{
        width: 70vw;
        height: 100vh;
        background-color: rgb(36, 22, 121);
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: anim_ 0.3s;
    }

    .menu :first-child {
        margin-top: 1vh;
    }

    .menu div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 2vh;
    }

    
}